<script setup lang="ts">
import { Space, Tag, Tooltip } from 'ant-design-vue'
import { map } from 'lodash-es'
import ParamTable from './param-table.vue'
import { SpecTypeMap } from '@/constant/thing-model'

const props = withDefaults(defineProps<{ properties: any[] }>(), { properties: () => [] })
const searchWords = ref()

const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '能力名称', dataIndex: 'abilityName' },
    { title: '能力编号', dataIndex: 'abilityCode' },
    { title: '数据类型', dataIndex: ['detail', 'dataType', 'type'], customRender({ record }) {
      return SpecTypeMap.get(record.detail.dataType.type)
    } },
    { title: '数据定义', dataIndex: ['detail', 'dataType'], customRender({ record }) {
      const type = record.detail.dataType.type
      const specs = record.detail.dataType.specs

      switch (type) {
        case 'INT':
        case 'DECIMAL':{
          const range = `${specs?.min != null && specs?.max != null ? `取值范围：${specs?.min} ~ ${specs?.max}` : ''}`
          const unit = `${specs?.unit ?? ''}${specs?.unitName ? `(${specs.unitName})` : ''}`
          const step = `${specs?.step != null ? `步长：${specs?.step}` : ''}`
          return h('div', [h('div', range + unit), h('div', step)])
        }
        case 'BOOL':{
          const trueValue = `true：${specs.true}`
          const falseValue = `false：${specs.false}`
          return h(Space, { direction: 'horizontal' }, () => [
            h(Tag, { color: 'default' }, () => trueValue),
            h(Tag, { color: 'default' }, () => falseValue),
          ])
        }
        case 'ENUM':{
          const dimensionalArray: any[] = []

          map(specs, (val, key) => {
            dimensionalArray.push([key, val])
          })

          const tags = dimensionalArray.map((item) => {
            return h(Tag, { color: 'default' }, () => `${item[0]}：${item[1]}`)
          })

          return h(
            Tooltip,
            { placement: 'top', overlayInnerStyle: { overflow: 'scroll', padding: '10px', 'min-width': '300px' }, color: '#ffffff', title: h(Space, { direction: 'horizontal', wrap: true }, () => tags) },
            () => h(Space, { direction: 'horizontal', wrap: true }, () => tags.slice(0, 2)),
          )
        }
        case 'DATE':{
          return '时间戳'
        }
        case 'DAY':{
          return '日期'
        }
        case 'STRUCT':{
          return '结构体'
        }
        case 'TEXT':{
          const len = specs.length
          return `数据长度：${len == null ? '不限长度' : `${len}字符`}`
        }

        default:
          break
      }

      return 'ss'
    } },
  ],
  config: {
    total: 0,
    loading: false,
    isBorder: false,
    isSerialNo: true,
    isSelection: true,
    isOperate: true,
  },
  pagination: false,
})

watch(() => props.properties, () => {
  state.data = props.properties
}, { immediate: true })

function expandedRowRender({ record }: any) {
  const type = record.detail.dataType.type
  if (type === 'STRUCT') {
    return h(ParamTable, { specs: record.detail.dataType.specs })
  }
}

function isExpand(record: any) {
  return record.detail.dataType.type === 'STRUCT'
}

function onSearch() {
  if (searchWords.value === '' || searchWords.value === undefined)
    state.data = props.properties
  state.data = props.properties.filter(item => item.abilityName.includes(searchWords.value))
}
</script>

<template>
  <table-layout>
    <template #headerRight>
      <a-space>
        <a-input v-model:value="searchWords" placeholder="请输入属性名称" allow-clear />
        <a-button type="primary" @click="onSearch">
          搜索
        </a-button>
      </a-space>
    </template>
    <template #content>
      <base-table v-bind="state" :expanded-row-render="expandedRowRender" :row-expandable="isExpand" />
    </template>
  </table-layout>
</template>
