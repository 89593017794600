import type { Ref } from 'vue'

function useProviderStore(driverDetail: Ref<any>) {
  //   return { name, video, abilities, initParamModel, propertyBindRuleModel, thingModel }
  return { driverDetail }
}

const [useProviderProvider, useProviderInject] = createInjectionState(useProviderStore)

export { useProviderProvider }

export const useProviderState = (): ReturnType<typeof useProviderStore> => useProviderInject()!
