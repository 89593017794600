<script setup lang="ts">
import EventTable from './event-table.vue'
import ServiceTable from './service-table.vue'
import PropertyTable from './property-table.vue'
import { useProviderState } from './driver-context'

const { driverDetail } = useProviderState()

const properties = computed(() => driverDetail.value?.abilities ? driverDetail.value?.abilities.filter((item: any) => item.abilityType === 'PROPERTY') : [])
const events = computed(() => driverDetail.value?.abilities ? driverDetail.value?.abilities.filter((item: any) => item.abilityType === 'EVENT') : [])
const services = computed(() => driverDetail.value?.abilities ? driverDetail.value?.abilities.filter((item: any) => item.abilityType === 'SERVICE') : [])

const activeKey = ref('1')
</script>

<template>
  <div h-full>
    <a-tabs v-model:active-key="activeKey">
      <a-tab-pane key="1" tab="属性">
        <block-content :need-padding="false" mt-8px>
          <PropertyTable :properties="properties" />
        </block-content>
      </a-tab-pane>
      <a-tab-pane key="2" tab="服务">
        <block-content :need-padding="false" mt-8px>
          <ServiceTable :services="services" />
        </block-content>
      </a-tab-pane>
      <a-tab-pane key="3" tab="事件" :need-padding="false">
        <block-content :need-padding="false" mt-8px>
          <EventTable :events="events" />
        </block-content>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/ant-reset/tabs.scss';
</style>
