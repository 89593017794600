<script setup lang="ts">
import { DownloadOutlined } from '@ant-design/icons-vue'
import type { SelectValue } from 'ant-design-vue/es/select'
import ability from './components/ability.vue'
import { useProviderProvider } from './components/driver-context'
import initParam from './components/init-param.vue'
import propertyBindRule from './components/property-bind-rule.vue'
import { getDriverDetailApi, getDriverListApi } from '@/api/driver'

type Command = 'ability' | 'initParam' | 'propertyBindRule'

const driverList = ref<any[]>([])
const driverDetail = ref()
const command = ref<Command>('ability')
const driverListModalVisible = ref(false)
const thingModelVisible = ref(false)
const driverName = ref()

useProviderProvider(driverDetail)

const tabs: Record<Command, any> = {
  ability,
  initParam,
  propertyBindRule,
}

const thingModel = computed(() => driverDetail.value?.thingModel)

onMounted(async () => {
  await getDrivers()
  if (driverList.value.length > 0) {
    driverName.value = driverList.value[0].value
    await getDriverDetail(driverName.value)
  }
})

async function getDriverDetail(driverName: string) {
  driverDetail.value = await getDriverDetailApi(driverName)
}

async function getDrivers() {
  const res = await getDriverListApi()
  driverList.value = res.map(item => ({ label: item, value: item }))
}

async function onChooseDriver(driverName: SelectValue) {
  await getDriverDetail(driverName as string)
  driverListModalVisible.value = false
}

function onOpenExportModal() {
  thingModelVisible.value = true
}
</script>

<template>
  <page-container>
    <content-header title="驱动仓库" />
    <flex-content>
      <div h-full flex flex-col>
        <div flex-none p-12px rounded style="background: var(--bg-color);">
          <a-row>
            <a-col :span="12">
              <div flex w-full justify-start items-center>
                驱动名称：
                <a-select v-model:value="driverName" flex-1 :options="driverList" show-search @change="(name) => { onChooseDriver(name) }" />
              </div>
            </a-col>
            <a-col :span="12">
              <div flex justify-end>
                <a-button type="link" @click="onOpenExportModal">
                  物模型
                  <template #icon>
                    <DownloadOutlined />
                  </template>
                </a-button>
              </div>
            </a-col>
          </a-row>
          <a-row mt-10px>
            <a-col :span="9">
              <div>
                <p>
                  驱动描述： {{ driverDetail?.description ?? '无描述' }}
                </p>
              </div>
            </a-col>
            <a-col :span="3">
              <div>
                <p>
                  是否有视频： <a-tag :color="driverDetail?.video ? 'processing' : 'default'">
                    {{ driverDetail?.video ? "有视频" : "无视频" }}
                  </a-tag>
                </p>
              </div>
            </a-col>
            <a-col :span="12" class="pl-45">
              <div flex justify-end>
                <a-radio-group v-model:value="command" button-style="solid">
                  <a-radio-button value="ability">
                    驱动能力
                  </a-radio-button>
                  <a-radio-button value="initParam">
                    初始化参数
                  </a-radio-button>
                  <a-radio-button value="propertyBindRule">
                    绑点参数
                  </a-radio-button>
                </a-radio-group>
              </div>
            </a-col>
          </a-row>
        </div>
        <div flex-1 mt-10px overflow-hidden>
          <component :is="tabs[command]" />
        </div>
      </div>
    </flex-content>

    <thing-model-export v-model:open="thingModelVisible" :thing-model="thingModel" />
  </page-container>
</template>
