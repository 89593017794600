<script setup lang="ts">
import { Card, Space, Tag } from 'ant-design-vue'
import ParamTable from './param-table.vue'
import { ThingModelEventTypeColorMap, ThingModelEventTypeMap } from '@/constant/thing-model'

const props = withDefaults(defineProps<{ events: any[] }>(), { events: () => [] })
const searchWords = ref()
const state = reactive<TableState>({
  data: [],
  columns: [
    { title: '能力名称', dataIndex: 'abilityName' },
    { title: '能力编号', dataIndex: 'abilityCode' },
    { title: '参数', dataIndex: ['detail', 'dataType', 'type'], customRender({ record }) {
      const outputData = record.detail?.outputData ?? []
      return h(Space, { direction: 'horizontal', wrap: true }, () => [
        h(Tag, { color: 'default' }, () => `出参：${outputData.length}个`),
      ])
    } },
    {
      title: '事件类型',
      dataIndex: ['detail', 'dataType', 'type'],
      customRender({ record }) {
        const type = record.detail?.type
        return h(Tag, { color: ThingModelEventTypeColorMap.get(type) }, () => ThingModelEventTypeMap.get(type) ?? 'ddd')
      },
    },
    { title: '备注', dataIndex: 'description' },
  ],
  config: {
    total: 0,
    loading: false,
    isBorder: false,
    isSerialNo: true,
    isSelection: true,
    isOperate: true,
  },
  pagination: false,
})

watch(() => props.events, () => {
  state.data = props.events
}, { immediate: true })

function expandedRowRender({ record }: any) {
  return h(Card, { title: '输出参数' }, () => h(ParamTable, { specs: record.detail.outputData }))
}

function onSearch() {
  if (searchWords.value === '' || searchWords.value === undefined)
    state.data = props.events
  state.data = props.events.filter(item => item.abilityName.includes(searchWords.value))
}
</script>

<template>
  <table-layout>
    <template #headerRight>
      <a-space>
        <a-input v-model:value="searchWords" placeholder="请输入事件名称" allow-clear />
        <a-button type="primary" @click="onSearch">
          搜索
        </a-button>
      </a-space>
    </template>
    <template #content>
      <base-table v-bind="state" :expanded-row-render="expandedRowRender" />
    </template>
  </table-layout>
</template>
