<script  lang="ts">
import { Space, Tag, Tooltip } from 'ant-design-vue'
import { map } from 'lodash-es'
import { SpecTypeMap } from '@/constant/thing-model'

const MyCom = defineComponent({
  name: 'MyCom',
  props: { specs: { type: Array<any>, default: () => [] } },
  setup(props) {
    const state = reactive<TableState>({
      data: [],
      columns: [
        { title: '参数名称', dataIndex: 'name' },
        { title: '是否必须', dataIndex: 'required', customRender({ record }) {
          return h(Tag, { color: record.required ? 'green' : 'default' }, () => record.required ? '必须' : '非必须')
        } },
        { title: '标识符', dataIndex: 'identifier' },
        { title: '数据类型', dataIndex: ['dataType', 'type'], customRender({ record }) {
          const type = record.dataType.type as DataTypeOfType
          return SpecTypeMap.get(type)
        } },
        { title: '数据描述', dataIndex: ['dataType', 'specs'], customRender({ record }) {
          const type = record.dataType.type as DataTypeOfType
          const specs = record.dataType.specs
          switch (type) {
            case 'INT':
            case 'DECIMAL':{
              const range = `${specs?.min != null && specs?.max != null ? `取值范围：${specs?.min} ~ ${specs?.max}` : ''}`
              const unit = `${specs?.unit ?? ''}${specs?.unitName ? `(${specs.unitName})` : ''}`
              const step = `${specs?.step != null ? `步长：${specs?.step}` : ''}`
              return h('div', [h('div', range + unit), h('div', step)])
            }
            case 'BOOL':{
              const trueValue = `true：${specs.true}`
              const falseValue = `false：${specs.false}`
              return h(Space, { direction: 'horizontal' }, () => [
                h(Tag, { color: 'default' }, () => trueValue),
                h(Tag, { color: 'default' }, () => falseValue),
              ])
            }

            case 'ENUM':{
              const dimensionalArray: any[] = []

              map(specs, (val, key) => {
                dimensionalArray.push([key, val])
              })

              const tags = dimensionalArray.map((item) => {
                return h(Tag, { color: 'default' }, () => `${item[0]}：${item[1]}`)
              })

              return h(
                Tooltip,
                { placement: 'top', overlayInnerStyle: { overflow: 'scroll', padding: '10px', 'min-width': '300px' }, color: '#ffffff', title: h(Space, { direction: 'horizontal', wrap: true }, () => tags) },
                () => h(Space, { direction: 'horizontal', wrap: true }, () => tags.slice(0, 2)),
              )
            }
            case 'DATE':
            case 'DAY':{
              return 'Long类型的UTC时间戳（毫秒）'
            }
            case 'TEXT':{
              const len = specs.length
              return `文本长度：${len == null ? '不限长度' : len}`
            }
            case 'STRUCT':{
              return '结构体'
            }
            case 'ARRAY':{
              const size = specs.size
              const innerType = specs.item.type
              // const innerSpecs = specs.item.specs
              return `内嵌【${SpecTypeMap.get(innerType)}】 限量${size}个`
            }

            default:
              return ''
          }
        } },
      ],
      config: {
        total: 0,
        loading: false,
        isBorder: false,
        isSerialNo: true,
        isSelection: true,
        isOperate: true,
      },
      pagination: false,
    })
    function expandedRowRender({ record }: any) {
      let specs
      const type = record.dataType.type
      if (type === 'ARRAY') {
        specs = record.dataType.specs.item.specs
      }
      else {
        specs = record.dataType.specs
      }

      return h('div', { style: { padding: '10px 0' } }, [h(MyCom, { specs })])
    }

    function isExpand(record: any) {
      return record.dataType.type === 'STRUCT' || record.dataType.type === 'ARRAY'
    }

    watch(() => props.specs, (newVal) => {
      state.data = newVal
    }, { immediate: true })

    return { state, expandedRowRender, isExpand }
  },
})

export default MyCom
</script>

<template>
  <base-table v-bind="state" :row-expandable="isExpand" :expanded-row-render="expandedRowRender" />
</template>
