<script setup lang="ts">
import { useProviderState } from './driver-context'
import ParamTable from './param-table.vue'

const { driverDetail } = useProviderState()

const searchWords = ref()
const initParamModel = ref([])

watchEffect(() => {
  initParamModel.value = driverDetail.value.initParamModel
})

function onSearch() {
  if (searchWords.value === '' || searchWords.value === undefined)
    initParamModel.value = driverDetail.value.initParamModel
  initParamModel.value = driverDetail.value.initParamModel.filter((item: any) => item.name.includes(searchWords.value))
}
</script>

<template>
  <table-layout>
    <template #headerRight>
      <a-space>
        <a-input v-model:value="searchWords" placeholder="请输入参数名称" allow-clear />
        <a-button type="primary" @click="onSearch">
          搜索
        </a-button>
      </a-space>
    </template>
    <template #content>
      <ParamTable :specs="initParamModel" />
    </template>
  </table-layout>
</template>

<style lang="scss" scoped></style>
